import i18n from "i18next"
import i18next from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import enTranslation from "./en.json"
import esTranslation from "./es.json"

const options = {
  // order and from where user language should be detected
  order: ["navigator", "querystring"],

  // keys or params to lookup language from
  lookupQuerystring: "lng",
  lookupCookie: "i18next",
  lookupLocalStorage: "i18nextLng",
  lookupSessionStorage: "i18nextLng",
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,
}

i18next.use(LanguageDetector).init({
  detection: options,
  fallbackLng: ["en"],
})

const resources = {
  en: {
    translation: enTranslation,
  },
  es: {
    translation: esTranslation,
  },
}

i18n.use(initReactI18next).init({
  resources,
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
