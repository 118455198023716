import React from "react"
import { useTranslation } from "react-i18next"

const Footer = () => {
  const { t, i18n } = useTranslation()
  return (
    <div
      style={{
        padding: `0 1.0875rem 1.45rem`,
        color: "#e91e63",
      }}
    >
      <footer>
        © {new Date().getFullYear()} Laura Prada M.D.,&nbsp;&nbsp;powered by
        {` `}
        <a
          href="https://www.jppcode.com"
          target="_blank"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          jppcode
        </a>
      </footer>
    </div>
  )
}

export default Footer
